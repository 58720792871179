<template>
    <div class="train_list mechanism" v-loading="loading" element-loading-text="拼命加载中">
        <header>
            <ul class="tab">
                <li class="tabs flex">
                    <div class="radios relative">
                        <div class="color66 span_name">行政区划：</div>
                        <selectCity marginLeft="90px" @getVal="setCityValue" style="width: 1200px"></selectCity>
                    </div>
                </li>
            </ul>
        </header>
        <section>
            <div class="breadcrumb">
                <span class="name">当前位置：</span>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/training/' }">职业培训</el-breadcrumb-item>
                    <el-breadcrumb-item>机构目录</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <ul class="mechanism_list flex flex-wrap">
                <li v-for="(n,i) in mechanismList " :key="i" class="relative">
                    <router-link :to="'./mechanismDetail?id='+n.id" target="_blank" :title="n.name">
                        <div class="top flex ">
                            <img :src="n.logo" alt="" class="logo img err_logo" :title="n.name">
                            <div class="name">
                                <h2>
                                    {{n.name}}
                                </h2>
                                <p>培训项目<span>{{n.trainNumber}}</span>个</p>
                            </div>
                            <div class="enterprise line_clamp1">合作单位：{{n.departName || '中国残联'}}</div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <status :type="type" v-if="isHide"></status>
        </section>
        <div class="pages">
            <el-pagination
                    v-if="page.total"
                    background
                    class="work_pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page.pageNum"
                    :page-size="page.pageSize"
                    layout="prev, pager, next, total, jumper"
                    :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import selectCity from "@/components/search/selectCity";
    import status from "@/components/public/status";
    export default {
        name: "mechanism",
        components: {
            selectCity,
            status
        },
        data() {
            return {
                mechanismList: [],
                page: {
                    pageNum: 1,
                    pageSize: 15,
                    total: 0,
                    cityId: '',
                    threecityId: ''
                },
                isHide:false,
                type:'',
                loading: false,
            }
        },
        watch: {
            page: {
                handler(newName, oldName) {
                    this.mechanism();
                },
                deep: true
            }
        },
        methods: {
            async mechanism() {
                this.loading = true;
                let res = await this.$api.mechanismListApi(this.page)
                if (res.data.success) {
                    this.loading = false;
                    this.mechanismList = res.data.data.records;
                    this.page.total = res.data.data.total;
                    if (this.mechanismList.length <= 0) {
                        this.isHide = true;
                        this.type = 'result';
                    }else{
                        this.isHide = false;
                    }
                }else{
                    this.isHide = true;
                    this.type = 'wifi';
                    this.loading = false;
                }
            },
            //地区筛选
            setCityValue(val) {
                this.page.cityId = val[0];
                this.page.threecityId = val[1];
            },
            //分页
            handleCurrentChange(val) {
                this.page.pageNum = val;
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
        },
        created() {
            this.mechanism();
          //判断是否移动端
          this.$mobile('https://lzmz.cdpee.org.cn/#/pages/train/train/directory');
        },
        mounted() {
            //不要动我的骚操作
            this.$emit('goRouter', '/training/')
        }
    }
</script>
z
<style scoped lang="less">
    @import "~assets/css/train.less";
</style>
